var render = function () {
  var _vm$companyData, _vm$companyData2, _vm$companyData2$pack, _vm$companyData3, _vm$companyData3$pack, _vm$companyData4, _vm$companyData4$pack, _vm$companyData5, _vm$companyData5$pack;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-card-text', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "cols": "12"
    }
  }, [[_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Current Package")]), (_vm$companyData = _vm.companyData) !== null && _vm$companyData !== void 0 && _vm$companyData.package ? _c('div', [_c('b-card-text', [_c('strong', [_vm._v("Title:")]), _vm._v(" " + _vm._s((_vm$companyData2 = _vm.companyData) === null || _vm$companyData2 === void 0 ? void 0 : (_vm$companyData2$pack = _vm$companyData2.package) === null || _vm$companyData2$pack === void 0 ? void 0 : _vm$companyData2$pack.title))]), _c('b-card-text', [_c('strong', [_vm._v("Billing Cycle:")]), ((_vm$companyData3 = _vm.companyData) === null || _vm$companyData3 === void 0 ? void 0 : (_vm$companyData3$pack = _vm$companyData3.package) === null || _vm$companyData3$pack === void 0 ? void 0 : _vm$companyData3$pack.billing_cycle) === 'monthly' ? [_vm._v(" Monthly ")] : ((_vm$companyData4 = _vm.companyData) === null || _vm$companyData4 === void 0 ? void 0 : (_vm$companyData4$pack = _vm$companyData4.package) === null || _vm$companyData4$pack === void 0 ? void 0 : _vm$companyData4$pack.billing_cycle) === 'yearly' ? [_vm._v(" Yearly ")] : [_vm._v(" Life Time ")]], 2), _c('b-card-text', [_c('strong', [_vm._v("Max User:")]), _vm._v(" " + _vm._s((_vm$companyData5 = _vm.companyData) === null || _vm$companyData5 === void 0 ? void 0 : (_vm$companyData5$pack = _vm$companyData5.package) === null || _vm$companyData5$pack === void 0 ? void 0 : _vm$companyData5$pack.max_user) + " users")]), _c('b-card-text', [_c('strong', [_vm._v("Subscription Expire Date:")]), _vm._v(" " + _vm._s(_vm.subscriptionExpireDate ? _vm.formatSubscriptionExpiredDateTime(_vm.subscriptionExpireDate) : 'N/A'))]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 159, 67, 0.15)',
      expression: "'rgba(255, 159, 67, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "border",
    attrs: {
      "variant": "flat-warning"
    },
    on: {
      "click": function click($event) {
        _vm.showModal = true;
      }
    }
  }, [_vm._v(" Cancel Subscription ")]), _c('router-link', {
    attrs: {
      "to": "/pricing"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "border mx-1",
    attrs: {
      "variant": "flat-success"
    }
  }, [_vm._v(" Update Package ")])], 1)], 1) : _vm._e()]], 2), _c('b-col', {
    attrs: {
      "lg": "6",
      "cols": "12"
    }
  }, [[_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Days")]), _c('div', [_c('label', [_vm._v(_vm._s(_vm.value) + " days remaining of your next payment")]), _c('b-progress', {
    attrs: {
      "max": _vm.max
    }
  }, [_c('b-progress-bar', {
    attrs: {
      "value": _vm.value
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.value) + " / " + _vm._s(_vm.max))])])], 1)], 1)]], 2)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$column2, _props$row, _props$row$currency, _props$column3, _props$column4;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_created_at' ? [props.row.created_at ? _c('div', [_vm._v(" " + _vm._s(_vm.formatDateTime(props.row.created_at)) + " ")]) : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_amount' ? [props.row.amount_paid ? _c('div', [_c('div', [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : (_props$row$currency = _props$row.currency) === null || _props$row$currency === void 0 ? void 0 : _props$row$currency.toUpperCase()) + " " + _vm._s(props.row.amount_paid) + " ")])]) : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_next_payment_date' ? [props.row.next_payment_date ? _c('div', [_vm._v(" " + _vm._s(_vm.formatDateTime(props.row.next_payment_date)) + " ")]) : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_status' ? [_c('b-badge', {
          attrs: {
            "variant": _vm.getStatusVariant(props.row.payment_status)
          }
        }, [_vm._v(" " + _vm._s(_vm.getStatusText(props.row.payment_status)) + " ")])] : _vm._e()];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)]), _c('b-modal', {
    attrs: {
      "title": "Cancel Subscription",
      "hide-footer": ""
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('p', {
    staticClass: "my-2"
  }, [_vm._v("Do you want to cancel now or at the end of your package?")]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "variant": "danger",
      "title": "If you cancel now, your subscription will end immediately. You won't be able to use the service after 24 hours."
    },
    on: {
      "click": _vm.pauseSubscription
    }
  }, [_vm._v(" Cancel Now ")]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "variant": "warning",
      "title": "If you cancel at the end of the package, you can continue using your subscription until it expires. After that, access will be revoked."
    },
    on: {
      "click": _vm.cancelAtEndSubscription
    }
  }, [_vm._v(" Cancel at End of Package ")])], 1), _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "secondary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        _vm.showModal = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }